import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Table, ProgressBar, Spinner } from 'react-bootstrap';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';
import { jsonToSend } from './jsonToSend'


const App = () => {

  const [myHeader, setMyHeader] = useState([])
  const [myFooter, setMyFooter] = useState([])
  const [headerData, setHeaderData] = useState("")
  const [footerData, setFooterData] = useState("")
  const [enableGeneratePdfs, setEnableGeneratePdfs] = useState(false)
  const [spinnerStatus, setSpinnerStatus] = useState(false)
  const [pdfGenerationSuccess, setPdfGenerationSuccess] = useState(false)
  const [selectedReportType, setSelectedReportType] = useState("compact")
  const [pdfData, setPdfData] = useState("")

  const base64HandlerForHeader = async () => {
    const base64Data = await getBase64(myHeader[0])
    // let theData = await base64Data
    let theData = await base64Data.split("base64,")[1]
    setHeaderData(theData)
    await base64HandlerForFooter()
  }

  const base64HandlerForFooter = async () => {
    const base64Data = await getBase64(myFooter[0])
    let theData = await base64Data.split("base64,")[1]
    // let theData = await base64Data
    setFooterData(theData)
    setEnableGeneratePdfs(true)
    setPdfGenerationSuccess(false)
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // turns into base64
    });
  };

  const sendJson = async () => {

    setSpinnerStatus(true)
    let wholeJson = jsonToSend
    wholeJson["headerBase64"] = headerData
    wholeJson["footerBase64"] = footerData
    wholeJson["reportType"] = selectedReportType

    if (wholeJson.headerBase64 !== "" && wholeJson.footerBase64 !== "") {
      var config2 = {
        method: 'post',
        url: 'https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/sampletest',
        headers: {
          'Content-Type': 'application/json',
        },
        data: wholeJson
      };

      console.log("what im sending\n", wholeJson)

      try {
        const axiosData = await axios(config2)
        console.log("inside axiosdata ===> \n\n", axiosData)
        console.log("required pdf data:", axiosData.data.pdfBase64)
        setPdfData(axiosData.data.pdfBase64)
        setSpinnerStatus(false)
        setPdfGenerationSuccess(true)
      } catch (error) {
        setSpinnerStatus(false)
        console.log("error =>", error)
      }

    }
  }

  const downloadPDF = () => {
    console.log("the file getting downloaded")
    const linkSource = `data:application/pdf;base64,${pdfData}`;
    const downloadLink = document.createElement("a");
    const fileName = `${selectedReportType}-Smart-Report.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  return (
    <div className="p-4">

      <div className='d-flex justify-content-center'>
        <div className='d-flex justify-content-center p-1' style={{ width: "400px", backgroundColor: "#e9ecef", borderRadius: "20px"}}>
          <h2>Smart Reports Portal</h2>
        </div>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>

        <label className='mt-3'><b>Choose Header</b></label>
        <input
          className="form-control"
          id="imageUploadHeader"
          type="file"
          onChange={(e) => {
            setMyHeader(e.target.files)
          }}
        />

        <label className='mt-3'><b>Choose Footer</b></label>
        <input
          className="form-control"
          id="imageUploadFooter"
          type="file"
          onChange={(e) => {
            setMyFooter(e.target.files)
          }}
        />

        <label className='mt-3'><b>Select Report Type</b></label>
        <select
          onClick={(e) => setSelectedReportType(e.target.value)}
          className="form-select mt-3"
          aria-label="Default select example"
        >
          <option value="compact">Compact</option>
          <option value="advanced">Advanced</option>
        </select>

        <button
          disabled={spinnerStatus}
          className="btn btn-sm btn-primary my-3 button-focus-css"
          onClick={base64HandlerForHeader}>
          {spinnerStatus ?
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                {"Getting Pdf "}
              </div>
              <div style={{ margin: "0px 0px 0px 5px" }}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            </div>
            :
            <>
              {"Save Changes"}
            </>
          }
        </button>

        <button
          disabled={enableGeneratePdfs === false}
          className="btn btn-sm btn-info mx-3 my-3 button-focus-css"
          onClick={sendJson}>
          Generate PDFs
        </button>

        <a href="#" onClick={downloadPDF} style={{ textDecoration: "none", display: `${pdfGenerationSuccess ? "" : "none"}` }}>
          download pdf <i className="fas fa-download"></i>
        </a>

      </form>

    </div>
  )
}

export default App